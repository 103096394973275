<template>
  <div class="app app-auth-sign-in align-content-stretch d-flex flex-wrap justify-content-end">
        <div class="app-auth-background" :class="{'auth-background-light': !night, 'auth-background-dark': night}">
        </div>
        <div class="app-auth-container">
            <!-- <div class="logo">
                <a href="#"></a>
            </div> -->
            <p class="auth-description">{{$t('login.auth_description')}}</p>

            <Form @submit="handleLogin" :validation-schema="schema">
              <div class="auth-credentials m-b-xxl">
                  <div class="form-group col-6">
                    <label for="signInEmail" class="form-label">{{$t('login.email_address')}}</label>
                    <Field type="email" class="form-control" id="signInEmail" aria-describedby="signInEmail" placeholder="example@varsanpr.com" name="username"/>
                    <ErrorMessage name="username" class="error-feedback" />
                  </div>

                  <div class="form-group col-6">
                    <label for="signInPassword" class="form-label m-t-md">{{$t('login.password')}}</label>
                    <Field type="password" name="password" class="form-control" id="signInPassword" aria-describedby="signInPassword" placeholder="password"/>
                    <ErrorMessage name="password" class="error-feedback" />
                  </div>

                  <p v-show="message" class="text-danger p-v-sm"> {{$t('login.errors.auth_failed')}}  </p>
              </div>
              <div class="auth-submit">
                  <button type="submit" :disabled="loading" class="btn btn-primary">
                    <span v-show="loading" class="spinner-border spinner-border-sm m-r-xxs"></span>
                    <span>{{$t('login.sign_in')}}</span>
                  </button>
                  <!-- <a href="javascript:void(0);" class="auth-forgot-password float-end">Forgot password?</a> -->
              </div>
            </Form>
        </div>
    </div>
</template>

<script>
import {Form, Field, ErrorMessage} from 'vee-validate';
import * as yup from 'yup';

export default {
  name: 'Login',
  components: {
    Form,
    Field,
    ErrorMessage
  },
  data(){
    const schema = yup.object().shape({
      username: yup.string().required(this.$t('login.errors.username')),
      password: yup.string().required(this.$t('login.errors.password')),
    });

    return {
      loading: false,
      message: "",
      schema
    }
  },
  mounted() {
    console.log(this.night);
    if(this.$route.query.token){
      this.loading = true;
      this.$store.dispatch('auth/checkToken', this.$route.query.token).then(() => {
        this.loading = false;
        this.$router.push('/');
      })
      .catch(() => {
        this.loading = false;
        window.location.href = 'https://gateway.varsanpr.com/login?redirect=' + window.location.origin + '/login';
      })
    }else{
      window.location.href = 'https://gateway.varsanpr.com/login?redirect=' + window.location.origin + '/login';
    }
  },
  computed: {
    loggedIn(){
      return this.$store.state.auth.status.loggedIn;
    },
    night(){
      return this.$store.state.auth.status.dark;
    }
  },
  created(){
    if(this.loggedIn){
      this.$router.push('/');
    }
  },
  methods: {
    handleLogin(user){
      this.loading = true;

      this.$store.dispatch('auth/login', user).then(() => {
        this.$router.push('/');
      },
      (error) => {
        this.loading = false;
        this.message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        console.log(this.message);
      })
    }
  }
}
</script>

<style scoped>
.app-auth-background {
  height: 100vh;
}

.auth-background-light {
  background: url('../../public/images/vars_logo_light.png') no-repeat;
  background-size: 75%;
  background-position: center;
}
.auth-background-dark {
  background: url('../../public/images/vars_logo_dark.png') no-repeat;
  background-size: 75%;
  background-position: center;
}
</style>
